import Cohort from "../../components/cohort/Cohort";
import ContactUs from "../../components/contactUs/ContactUs";
import PaymentPlan from "../../components/paymentPlan/PaymentPlan";

const Enroll = () => {
  return (
    <section>
      <div className="container">
        <div className="title">
          <h2 className="s-head --mb2">Enroll Now</h2>
          <Cohort />
          <div className="--center-all">
            <p className="--my2 --width-500px --text-sm">
              To enroll yourself or another person in this bootcamp <br />
              Please select a Tuition payment plan and make payment.
            </p>
          </div>
        </div>
        <hr />
        <div>
          <h2 className="--text-center --pt">FrontEnd Bootcamp Pricing</h2>
        </div>
        <br />
        <div className="--flex-center --flex-dir-column">
          <PaymentPlan
            theme={"--btn-success"}
            plan={"Upfront"}
            desc={"Pay once (Save $50)"}
            desc2={"One time payment."}
            amount={"400"}
            // naira={"250k"}
            discount={"$50"}
            paymentLink={"https://flutterwave.com/pay/frontend-bootcamp"}
          />
          &nbsp; &nbsp; &nbsp;
          <PaymentPlan
            theme={"--btn-primary"}
            plan={"Three installments"}
            desc={"Three installments"}
            desc2={"Monthly for the first 3months."}
            amount={"150"}
            // naira={"91k"}
            discount={"$0"}
            paymentLink={
              "https://flutterwave.com/pay/frontend-bootcamp-installment"
            }
          />
        </div>
        <br />
        <hr />
        <div>
          <h2 className="--text-center --pt">FullStack Bootcamp Pricing</h2>
        </div>
        <br />
        <div className="--flex-center --flex-dir-column">
          <PaymentPlan
            theme={"--btn-purple"}
            plan={"Upfront"}
            desc={"Pay once (Save $200)"}
            desc2={"One time payment."}
            amount={"1,000"}
            // naira={"250k"}
            discount={"$200"}
            paymentLink={"https://flutterwave.com/pay/fullstack-bootcamp"}
          />
          &nbsp; &nbsp; &nbsp;
          <PaymentPlan
            theme={"--btn-blue"}
            plan={"Three installments"}
            desc={"Three installments"}
            desc2={"Monthly for the first 3months."}
            amount={"400"}
            // naira={"91k"}
            discount={"$0"}
            paymentLink={
              "https://flutterwave.com/pay/fullstack-bootcamp-installment"
            }
          />
        </div>
        <br />
        <hr />
        <div className="--center-all --p2 ">
          <p className="--text-center">
            If you are in Nigeria and prefer a bank transfer.
            <br />
            <ContactUs />
          </p>
        </div>
      </div>
    </section>
  );
};

export default Enroll;
