import { doc, setDoc, Timestamp } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../../../firebase/config";
import Card from "../../card/Card";
import Loader from "../../loader/Loader";
import styles from "./ChangeUserStatus.module.scss";

const ChangeUserStatus = ({ user, id }) => {
  const [access, setAccess] = useState("");
  const [cohort, setCohort] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const editUser = (e, id) => {
    e.preventDefault();
    setIsLoading(true);

    const userConfig = {
      id: user.id,
      name: user.name,
      email: user.email,
      access: access,
      cohort: cohort,
      createdAt: user.createdAt,
      editedAt: Timestamp.now().toDate(),
    };
    try {
      setDoc(doc(db, "users", id), userConfig);

      setIsLoading(false);
      toast.success("User status changes successfully");
      navigate("/admin/home");
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <div className={styles.status}>
        <Card cardClass={styles.card}>
          <h4>Update User Status</h4>
          <form onSubmit={(e) => editUser(e, id)}>
            <span>
              <p>User Access:</p>
              <select
                value={access}
                onChange={(e) => setAccess(e.target.value)}
              >
                <option value="" disabled>
                  -- Choose one --
                </option>
                <option value="none">None</option>
                <option value="level-1">Level 1 - FED</option>
              </select>
            </span>
            <span>
              <p>User Cohort:</p>
              <select
                value={cohort}
                onChange={(e) => setCohort(e.target.value)}
              >
                <option value="" disabled>
                  -- Choose one --
                </option>
                <option value="none">None</option>
                <option value="cohort-1">Cohort 1</option>
              </select>
            </span>
            <span>
              <button type="submit" className="--btn --btn-primary">
                Update Status
              </button>
            </span>
          </form>
        </Card>
      </div>
    </>
  );
};

export default ChangeUserStatus;
