import React from "react";
import Card from "../../card/Card";
import styles from "../Fed.module.scss";
import ContactLink from "../../contactLink/ContactLink";
import ProtectFed from "../../hiddenPages/protectFed";

const Conclusion = () => {
  return (
    <ProtectFed>
      <div>
        <h2>Graduation lecture</h2>
        <hr />
        <br />
        <Card cardClass={styles.card}>
          <p>
            Congratulation on completing this bootcamp. Now we would host a live
            class where we would address the following,
          </p>
          <ol className="--text-p --fw-bold --ml2">
            <li>Making money in Tech</li>
            <li>Getting a job</li>
            <li>Freelancing</li>
            <li>More learning</li>
            <li>Issuing certificate of completion.</li>
          </ol>
          <br />
          <p>
            <b>NOTE:</b> The time of the class would be communicated to you.
          </p>
          <br />
          <ContactLink />
        </Card>
        <br />
      </div>
    </ProtectFed>
  );
};

export default Conclusion;
