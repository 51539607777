import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AllContext from "../../context/allContext";
import useFetchCollection from "../../customHooks/useFetchCollection";

import ContactLink from "../contactLink/ContactLink";

const ProtectAdmin = ({ children }) => {
  const [userAccess, setUserAccess] = useState("");
  const { data } = useFetchCollection("users");
  //   console.log(data);
  const { userID } = useContext(AllContext);

  useEffect(() => {
    setUserAccess("none");
    if (data !== []) {
      const userIndex = data.findIndex((item) => {
        return item.id === userID;
      });
      //   console.log(userIndex);
      if (userIndex !== -1) {
        const thisUser = data.find((item) => {
          return item.id === userID;
        });
        // console.log(thisUser);
        setUserAccess(thisUser.access);
      }
    }
  }, [data, userID]);

  if (userAccess === "admin") {
    return children;
  }
  return (
    <section>
      <div className="container">
        <h2>Permission Denied.</h2>
        <p>This content of this page can only be view by an Admin.</p>
        <br />
        <ContactLink />
        <br />
        <Link to="/">
          <button className="--btn">&larr; Back To Home</button>
        </Link>
      </div>
    </section>
  );
};

export default ProtectAdmin;
