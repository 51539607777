import React from "react";
import "./Curriculum.scss";
import { AiOutlineHtml5 } from "react-icons/ai";
import { SiExpress, SiJavascript, SiMongodb } from "react-icons/si";
import { FaReact } from "react-icons/fa";
import { IoLogoNodejs } from "react-icons/io";
import { Link } from "react-router-dom";
import Cohort from "../../cohort/Cohort";

const Curriculum = () => {
  return (
    <section className="service-section">
      <div className="container --pb2">
        <div className="title">
          <h2 className="s-head --mb2">FrontEnd Dev Curriculum</h2>
          <p className="--my2">
            Here is a summary of the <b>key</b> technologies
            <br />
            and tools you would learn in the FrontEnd Developer Bootcamp.
          </p>
          <Cohort />
          <br />
        </div>
        <div className="service">
          <div className="service-box">
            <AiOutlineHtml5 size={35} color="orangered" />
            <h3 className="t-head">HTML, CSS, SASS</h3>
            <p>
              HTML stands for <b>Hypertext</b> Markup language and it is the
              bedrock of the web that helps us to structure our webpage. CSS
              stands for <b>Cascading</b> stylesheet and it helps us to add
              style to a webpage.
            </p>
          </div>
          <div className="service-box">
            <SiJavascript size={35} color="#c4d904" />
            <h3 className="t-head">JavaScript</h3>
            <p>
              {" "}
              <b>JavaScipt</b> is a programming language that helps you add
              interactivity to you web pages. JavaScript is the most popular
              language and it has so many applications.
            </p>
          </div>
          <div className="service-box">
            <FaReact size={35} color="blue" />
            <h3 className="t-head">React</h3>
            <p>
              <b>Reach</b> is a JavaScript Library for building user interfaces.
              It is a tool that helps us intelligently build applications that
              can be used by millions on people globally.
            </p>
          </div>
        </div>
        <p className="--text-center --my2">
          <Link to="/enroll" className="btn">
            Enroll Now
          </Link>
        </p>
      </div>
      <hr />
      <div className="container --mt2">
        <div className="title">
          <h2 className="s-head --mb2">FullStack Dev Curriculum</h2>
          <p className="--my2">
            In addition to the knowledge gained from the
            <br />
            <b>FrontEnd Development Training</b>, here is a summary <br /> of
            the <b>key</b> technologies and tools you would learn in the
            <br /> <b>FullStack Developer Bootcamp.</b>
          </p>
          <br />
        </div>
        <div className="service">
          <div className="service-box">
            <IoLogoNodejs size={35} color="orangered" />
            <h3 className="t-head">NodeJS</h3>
            <p>
              Node.js is an open-source, cross-platform,{" "}
              <b>back-end JavaScript</b> runtime environment that runs on a
              JavaScript Engine and executes JavaScript code outside a web
              browser, which was designed to build scalable network
              applications.
            </p>
          </div>
          <div className="service-box">
            <SiExpress size={35} color="#c4d904" />
            <h3 className="t-head">Express</h3>
            <p>
              {" "}
              <b>Express.js</b>, or simply Express, is a back end web
              application framework for building RESTful APIs with Node.js. It
              is designed for building web applications and APIs.
            </p>
          </div>
          <div className="service-box">
            <SiMongodb size={35} color="blue" />
            <h3 className="t-head">MongoDB</h3>
            <p>
              <b>MongoDB</b> is a open-source cross-platform document-oriented
              database program. Classified as a NoSQL database program, MongoDB
              uses JSON-like documents with optional schemas.
            </p>
          </div>
        </div>
        <p className="--text-center --my2">
          <Link to="/enroll" className="btn">
            Enroll Now
          </Link>
        </p>
      </div>
    </section>
  );
};

export default Curriculum;
