import React from "react";
import Card from "../../card/Card";
import styles from "../Fed.module.scss";
import reactImg from "../../../assets/react.png";
import CourseCard from "../../courseCard/CourseCard";
import ContactLink from "../../contactLink/ContactLink";
import ProtectFed from "../../hiddenPages/protectFed";

const ReactJS = () => {
  return (
    <ProtectFed>
      <div>
        <h2>React</h2>
        <hr />
        <br />
        <Card cardClass={styles.card}>
          <p>
            In this section, your are going to learn ReactJS, a Javascript
            library for building user interfaces.
          </p>
          {/* <br /> */}
          <p>As part of this bootcamp, begin the course below.</p>
          <br />
          <p>
            {" "}
            <b>Course Duration:</b> 4-Weeks or 28-Days
          </p>
          <br />
          <p>
            <b>NOTE:</b> if you are fully enrolled in this bootcamp, you would
            have gotten an email giving you access to the course.
          </p>
          <br />
          <ContactLink />
        </Card>
        <br />
        <CourseCard
          img={reactImg}
          title={"React - Beginner To Advanced"}
          link={"https://course.zinotrustacademy.com"}
          cta={"Begin Course"}
        />
      </div>
    </ProtectFed>
  );
};

export default ReactJS;
