import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <section>
      <div className="container">
        <div className="title">
          <h2 className="s-head --mb2">Page Not Found</h2>

          <div className="--center-all">
            <p className="--my2 --width-500px">
              Opppsss, Looks like this page is either not found or not properly
              loaded.
              <br />
            </p>
            <Link to="/">
              <button className="--btn --btn-primary">Back To Home</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
