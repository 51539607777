import { useState, useContext, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import styles from "./Header.module.scss";
// import { FaTimes } from "react-icons/fa";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import logoImg from "../../assets/logo.png";
import AllContext from "../../context/allContext";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from "../../firebase/config";
import { toast } from "react-toastify";
import ShowOnLogin from "../hiddenLink/showOnLogin";
import ShowOnLogout from "../hiddenLink/showOnLogout";
import ShowOnlyAdmin from "../hiddenLink/showOnlyAdmin";
import useFetchCollection from "../../customHooks/useFetchCollection";
import { doc, setDoc, Timestamp } from "firebase/firestore";

const activeLink = ({ isActive }) => (isActive ? `${styles.active}` : "");

const logo = (
  <div className={styles.logo}>
    <Link to="/">
      <img src={logoImg} alt="Logo" />
    </Link>
  </div>
);

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [scrollPage, setScrollPage] = useState(false);
  const { setIsLoggedIn, setUsername, username, setUserID, isLoggedIn } =
    useContext(AllContext);
  const { data } = useFetchCollection("users");

  const navigate = useNavigate();

  const fixNavbar = () => {
    if (window.scrollY > 50) {
      setScrollPage(true);
    } else {
      setScrollPage(false);
    }
  };
  window.addEventListener("scroll", fixNavbar);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const hideMenu = () => {
    setShowMenu(false);
  };

  // Monitor currently sign in user
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // console.log(user);
        setIsLoggedIn(true);
        setUsername(user.displayName);
        setUserID(user.uid);
        // Save this user to firestore on first login
        if (data.length > 0 && user) {
          const userIndex = data.findIndex((item) => {
            return item.id === user.uid;
          });

          // console.log(data);
          // console.log(data.length, userIndex, isLoggedIn);

          // if (isLoggedIn && data.length > 0 && userIndex === -1) {
          //   setDoc(doc(db, "users", user.uid), {
          //     id: user.uid,
          //     name: user.displayName,
          //     email: user.email,
          //     access: "none",
          //     cohort: "none",
          //     createdAt: Timestamp.now().toDate(),
          //   });
          //   console.log("User Created");
          // }
          console.log("User already Exists");
        }
      } else {
        // console.log("Logged out");
        setIsLoggedIn(false);
        setUsername("");
        setUserID("");
      }
    });
    // console.log(isLoggedIn);
  }, [setIsLoggedIn, setUsername, setUserID, data, isLoggedIn]);

  const logoutUser = () => {
    signOut(auth)
      .then(() => {
        setUsername("");
        setIsLoggedIn(false);
        navigate("/login");
        toast.success("Logout successfully.");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <header className={scrollPage ? `${styles.fixed}` : null}>
      <div className={styles.header}>
        {logo}

        <nav
          className={
            showMenu ? `${styles["show-nav"]}` : `${styles["hide-nav"]}`
          }
        >
          <div
            className={
              showMenu
                ? `${styles["nav-wrapper"]} ${styles["show-nav-wrapper"]}`
                : `${styles["nav-wrapper"]}`
            }
            onClick={hideMenu}
          ></div>

          <ul onClick={hideMenu}>
            <ShowOnLogin>
              <li>
                <p style={{ fontWeight: "bold" }}>
                  <span style={{ color: "#fff" }}>Hi, </span>
                  <span style={{ color: "orangered" }}>{username}</span>
                  <span style={{ color: "#fff" }}> |</span>
                </p>
              </li>
            </ShowOnLogin>
            {/* <li className={styles["logo-mobile"]}>
              {logo}
              <FaTimes size={22} color="#fff" onClick={hideMenu} />
            </li> */}
            <li>
              <NavLink to="/" className={activeLink}>
                Home
              </NavLink>
            </li>
            <ShowOnLogin>
              <li>
                <NavLink to="/fed/home" className={activeLink}>
                  FrontEnd
                </NavLink>
              </li>
            </ShowOnLogin>

            <ShowOnlyAdmin>
              <li>
                <NavLink to="/admin/home" className={activeLink}>
                  Admin
                </NavLink>
              </li>
            </ShowOnlyAdmin>
            <ShowOnLogout>
              <li>
                <NavLink to="/login">
                  <button className="--btn --btn-primary">Login</button>
                </NavLink>
              </li>
            </ShowOnLogout>
            <ShowOnLogin>
              <li>
                <NavLink to="/" onClick={logoutUser}>
                  Logout
                </NavLink>
              </li>
            </ShowOnLogin>
            <li>
              <NavLink to="/enroll">
                <button className="--btn --btn-danger">Enroll Now</button>
              </NavLink>
            </li>
          </ul>
        </nav>

        <div className={styles["menu-icon"]}>
          <HiOutlineMenuAlt3 size={28} onClick={toggleMenu} />
        </div>
      </div>
    </header>
  );
};

export default Header;
