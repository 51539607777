import { createContext } from "react";
import useLocalStorage from "use-local-storage";

const AllContext = createContext();

export const AllContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage(false);
  const [username, setUsername] = useLocalStorage("");
  const [userExists, setUserExists] = useLocalStorage(false);
  const [userID, setUserID] = useLocalStorage("");

  return (
    <AllContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        username,
        setUsername,
        userExists,
        setUserExists,
        userID,
        setUserID,
      }}
    >
      {children}
    </AllContext.Provider>
  );
};

export default AllContext;
