import About from "../../components/home/about/About";
import Curriculum from "../../components/home/curriculum/Curriculum";
import Faq from "../../components/home/faq/Faq";
import Hero from "../../components/home/hero/Hero";

const Home = () => {
  return (
    <div>
      <Hero />
      <About />
      <Curriculum />
      <Faq />
    </div>
  );
};

export default Home;
