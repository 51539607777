import { useContext } from "react";
import { NavLink } from "react-router-dom";
import styles from "./Navbar.module.scss";
import { FaUserCircle } from "react-icons/fa";
import AllContext from "../../../context/allContext";

const activeLink = ({ isActive }) => (isActive ? `${styles.active}` : "");

const Navbar = () => {
  const { username } = useContext(AllContext);
  return (
    <div className={styles.navbar}>
      <div className={styles.user}>
        <FaUserCircle size={40} color="#fff" />
        <h4>{username}</h4>
      </div>
      <nav>
        <ul>
          <li>
            <NavLink to="/fed/home" className={activeLink}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/fed/roadmap" className={activeLink}>
              Step 1 - Roadmap
            </NavLink>
          </li>
          <li>
            <NavLink to="/fed/html-css" className={activeLink}>
              Step 2 - HTML {"&"} CSS
            </NavLink>
          </li>
          <li>
            <NavLink to="/fed/html-css-test" className={activeLink}>
              Step 3 - HTML {"&"} CSS Tests
            </NavLink>
          </li>
          <li>
            <NavLink to="/fed/css-framework" className={activeLink}>
              Step 4 - CSS Framework
            </NavLink>
          </li>
          <li>
            <NavLink to="/fed/javascript" className={activeLink}>
              Step 5 - JavaScript
            </NavLink>
          </li>
          <li>
            <NavLink to="/fed/javascript2" className={activeLink}>
              Step 6 - JavaScript Projects
            </NavLink>
          </li>
          <li>
            <NavLink to="/fed/react" className={activeLink}>
              Step 7 - React
            </NavLink>
          </li>
          <li>
            <NavLink to="/fed/conclusion" className={activeLink}>
              Step 8 - Conclusion
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
