import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AllContext from "../../context/allContext";
import useFetchCollection from "../../customHooks/useFetchCollection";

import ContactLink from "../contactLink/ContactLink";

const ProtectFed = ({ children }) => {
  const [userAccess, setUserAccess] = useState("");
  const { data } = useFetchCollection("users");
  // console.log(data);
  const { userID } = useContext(AllContext);
  // console.log(userID);

  useEffect(() => {
    setUserAccess("none");
    if (data !== []) {
      const userIndex = data.findIndex((item) => {
        return item.id === userID;
      });
      //   console.log(userIndex);
      if (userIndex !== -1) {
        const thisUser = data.find((item) => {
          return item.id === userID;
        });
        // console.log(thisUser);
        setUserAccess(thisUser.access);
      }
    }
  }, [data, userID]);

  //   return <h2>Protect FED</h2>;

  if (userAccess === "level-1" || userAccess === "admin") {
    return children;
  }
  return (
    <section>
      <div className="container">
        <h2>Permission Denied.</h2>
        <p>
          This content of this page can only be view by a FrontEnd Dev student.
        </p>
        <br />
        <ContactLink />
        <br />
        <Link to="/">
          <button className="--btn">&larr; Back To Home</button>
        </Link>
      </div>
    </section>
  );
};

export default ProtectFed;
