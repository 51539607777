import React from "react";
import ProtectAdmin from "../../hiddenPages/protectAdmin";
import Users from "../users/Users";

const Home = () => {
  return (
    <ProtectAdmin>
      <div>
        <h2>Admin Page</h2>
        <hr />
        <Users />
      </div>
    </ProtectAdmin>
  );
};

export default Home;
