import React from "react";

const ContactUs = () => {
  return (
    <div>
      <div className="--center-all --p ">
        <h4 className="--text-center">
          Feel free to contact us.
          <br />
          <a href="tel:+2347051316235">+234 705 131 6235</a>|
          <a href="mailto:zinotrust@gmail.com">Email Me</a>
        </h4>
      </div>
    </div>
  );
};

export default ContactUs;
