import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/home/Home";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Login from "./pages/auth/Login";
import MyLogin from "./pages/auth/MyLogin";
import Fed from "./pages/fed/Fed";
import Admin from "./pages/admin/Admin";
import Enroll from "./pages/enroll/Enroll";
import Success from "./components/success/Success";
import NotFound from "./components/404/NotFound";
function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<MyLogin />} />
          <Route path="/enroll" element={<Enroll />} />
          <Route path="/success" element={<Success />} />

          <Route path="/fed/*" element={<Fed />} />
          <Route path="/admin/*" element={<Admin />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
