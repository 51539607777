import React from "react";
import Card from "../../card/Card";
import styles from "../Fed.module.scss";
import jsImg from "../../../assets/100doj.png";
import CourseCard from "../../courseCard/CourseCard";
import ContactLink from "../../contactLink/ContactLink";
import ProtectFed from "../../hiddenPages/protectFed";

const Javascipt2 = () => {
  return (
    <ProtectFed>
      <div>
        <h2>JavaScript Projects Course</h2>
        <hr />
        <br />
        <Card cardClass={styles.card}>
          <p>
            The best way to get confortable with Javascrip is to build project.
            The course below is a Javascipt project based course that would help
            you become a better javascript developer.
          </p>
          {/* <br /> */}
          <p>As part of this bootcamp, begin the course below.</p>
          <br />
          <p>
            {" "}
            <b>Course Duration:</b> 3-Weeks or 21-Days
          </p>
          <br />
          <p>
            <b>NOTE:</b> if you are fully enrolled in this bootcamp, you would
            have gotten an email giving you access to the course.
          </p>
          <br />
          <ContactLink />
        </Card>
        <br />
        <CourseCard
          img={jsImg}
          title={"100 Days of JavaScript"}
          link={"https://course.zinotrustacademy.com"}
          cta={"Begin Course"}
        />
      </div>
    </ProtectFed>
  );
};

export default Javascipt2;
