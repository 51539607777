import React from "react";
import Card from "../../card/Card";
import styles from "../Fed.module.scss";
import htmlImg from "../../../assets/web-design.png";
import CourseCard from "../../courseCard/CourseCard";
import ContactLink from "../../contactLink/ContactLink";
import ProtectFed from "../../hiddenPages/protectFed";

const HtmlCss = () => {
  return (
    
      <div>
        <h2>HTML {"&"} CSS</h2>
        <hr />
        <br />
        <Card cardClass={styles.card}>
          <p>
            In this section, your are going to learn about HTML {"&"} CSS, which
            are used to create responsive webites.
          </p>
          {/* <br /> */}
          <p>As part of this bootcamp, begin the course below.</p>
          <br />
          <p>
            {" "}
            <b>Course Duration:</b> 3-Weeks or 21-Days
          </p>
          <br />
          <p>
            <b>NOTE:</b> if you are fully enrolled in this bootcamp, you would
            have gotten an email giving you access to the course.
          </p>
          <br />
          <ContactLink />
        </Card>
        <br />
        <CourseCard
          img={htmlImg}
          title={"Web Design Course - HTML, CSS & SASS"}
          link={"https://course.zinotrustacademy.com"}
          cta={"Begin Course"}
        />
        <br />
        <CourseCard
          img={"https://i.ibb.co/PCGrypq/HTML-1.png"}
          title={"HTML QUIZ"}
          link={"https://course.zinotrustacademy.com"}
          cta={"Take Quiz"}
        />
        <br />
        <CourseCard
          img={"https://i.ibb.co/Jp92434/HTML-2.png"}
          title={"BASIC CSS QUIZ"}
          link={"https://course.zinotrustacademy.com"}
          cta={"Take Quiz"}
        />
      </div>
    
  );
};

export default HtmlCss;
