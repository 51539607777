import React from "react";
import Card from "../card/Card";
import styles from "../fed/Fed.module.scss";

const ProjectCard = ({
  title,
  desc,
  img,
  previewURL,
  downloadURL,
  solutionURL,
}) => {
  return (
    <div>
      <Card cardClass={styles.card}>
        <h4 className="--color-danger">{title}</h4>
        <p>{desc}</p>
        <br />
        <hr />
        <div className="--flex-between --my">
          <a href={previewURL} target="_blank" rel="noreferrer">
            <button className="--btn --btn-outline">Preview Project</button>
          </a>
          <a href={downloadURL} target="_blank" rel="noreferrer">
            <button className="--btn --btn-primary">
              Download Project Files
            </button>
          </a>
          <a href={solutionURL} target="_blank" rel="noreferrer">
            <button className="--btn --btn-danger">View Solution</button>
          </a>
        </div>
        <hr />
        <br />
        <img src={img} alt="url shortener" width="100%" />
      </Card>
    </div>
  );
};

export default ProjectCard;
