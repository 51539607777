import React from "react";
import Card from "../../card/Card";
import styles from "../Fed.module.scss";
import jsImg from "../../../assets/modern-js.png";
import CourseCard from "../../courseCard/CourseCard";
import ContactLink from "../../contactLink/ContactLink";
import ProtectFed from "../../hiddenPages/protectFed";

const Javascipt = () => {
  return (
    <ProtectFed>
      <div>
        <h2>JavaScript</h2>
        <hr />
        <br />
        <Card cardClass={styles.card}>
          <p>
            In this section, your are going to learn JavaScript which is the
            most popular programming language that adds interactivity to a
            webpage.
          </p>
          {/* <br /> */}
          <p>As part of this bootcamp, begin the course below.</p>
          <br />
          <p>
            {" "}
            <b>Course Duration:</b> 3-Weeks or 21-Days
          </p>
          <br />
          <p>
            <b>NOTE:</b> if you are fully enrolled in this bootcamp, you would
            have gotten an email giving you access to the course.
          </p>
          <br />
          <ContactLink />
        </Card>
        <br />
        <CourseCard
          img={jsImg}
          title={"Modern JavaScript Bootcamp"}
          link={"https://course.zinotrustacademy.com"}
          cta={"Begin Course"}
        />
        <br />
        <CourseCard
          img={"https://i.ibb.co/Vm650TV/HTML-3.png"}
          title={"BASIC JavaScript QUIZ"}
          link={"https://course.zinotrustacademy.com"}
          cta={"Take Quiz"}
        />
      </div>
    </ProtectFed>
  );
};

export default Javascipt;
