import React from "react";
import Card from "../card/Card";
import styles from "../fed/Fed.module.scss";

const CourseCard = ({ img, title, link, cta }) => {
  return (
    <Card cardClass={styles.courseCard}>
      <img src={img} alt="img" width={300} />
      <div className="--center-all">
        <h4 className="--color-danger">{title}</h4>
        <a href={link} target="_blank" rel="noreferrer">
          <button className="--btn --btn-primary">{cta}</button>
        </a>
      </div>
    </Card>
  );
};

export default CourseCard;
