export const questions = [
  {
    id: 1,
    title: "What is this bootcamp about?",
    answer:
      "This is the Frontend Developer Bootcamp that is structured to teach you everything you need to know to become a Frontend Developer",
  },
  {
    id: 2,
    title: "How can I register?",
    answer:
      "Simply click on the Apply Now button in the header menu, you would be taken to a page where you can enter your details and make payment.",
  },
  {
    id: 3,
    title: "How much is the bootcamp fees?",
    answer: "The bootcamp fee is $300 or (N150k)",
  },
  {
    id: 4,
    title: "After registration, what next?",
    answer:
      "We would contact you within 24 hours following payment confirmation and give you specific details about the program",
  },
  {
    id: 5,
    title: "How long is the bootcamp?",
    answer: "The bootcamp is for 9months.",
  },
  {
    id: 6,
    title: "Will I get a certificate at the end?",
    answer: "Yes, you will.",
  },
  {
    id: 7,
    title: "Do I have to quit my Job to follow this bootcamp?",
    answer:
      "No, you don't. This bootcamp is designed to help people transition from their current career to Tech.",
  },
  {
    id: 8,
    title: "How many hours per day is required?",
    answer:
      "You would need to consistently put in about 2hours per day to benefit maximally from this bootcamp.",
  },
  {
    id: 9,
    title: "Is the bootcamp online or offline?",
    answer:
      "The bootcamp is structured to be taken by anyone anywhere in the world right from the comfort of their home. So yes, its an online bootcamp. However, there would be live classes and mentorship sessions with your instructors.",
  },
  {
    id: 10,
    title: "Can I enroll my child for this bootcamp?",
    answer: "Yes, anyone from age 13 can take this bootcamp.",
  },
];
