import React from "react";
import Card from "../../card/Card";
import styles from "../Fed.module.scss";

const Home = () => {
  return (
    <div>
      <h2>FrontEnd Development Bootcamp</h2>
      <hr />
      <p>
        Welcome to the FrontEnd Development bootcamp where you would learn all
        you need to become <b> FrontEnd Developer</b>.
      </p>
      <br />
      <Card cardClass={styles.card}>
        <h4 className="--color-danger">Brief Intro To Web Development</h4>
        <p>
          Web development is a branch of Tech that involves building
          applications or softwares that run on the web.
        </p>
        <p>
          Examples of web applications are social networking sites like Facebook
          or e-commerce sites like Amazon.
        </p>
        <br />
        <p>
          The good news is that learning web development is not that hard! You
          only need to have some determination and guidiance.
        </p>
        <p>This bootcamp is structured to give you a bit of both.</p>
      </Card>
      <br />
      <Card cardClass={styles.card}>
        <h4 className="--color-danger">Branches Web Development</h4>
        <p>There are two broad divisions of web development</p>
        <p>
          1. <b> Frontend development</b> (also called client-side development):
          involves building the part of a website that is seen in the browser.
          This is done with three codes – HTML, CSS and JavaScript.
        </p>
        <br />
        <p>
          2. <b> Backend development</b> (also called server-side development):
          this controls what goes on behind the scenes of a web application.
          More on this later.
        </p>
      </Card>
      <br />
      <Card cardClass={styles.card}>
        <h4 className="--color-danger">Bootcamp aim and duration</h4>
        <p>
          This bootcamp would run for 6 - 9months at the end of which you would
          be equipped with the knowledge you need to either work in an
          organization as a FrontEnd Developer or as a freelancer.
        </p>
        <br />
        <p>
          <b>Note:</b> You are required to put in about 1 - 2 hours daily and
          consistently to achieve this above goal.
        </p>
      </Card>
      <br />
      <Card cardClass={styles.card}>
        <h4 className="--color-danger">Learning Structure</h4>
        <p>You would learn using various learning materials which includes</p>
        <p>1. Online Courses</p>
        <p>2. Quizes</p>
        <p>3. Tests and assignments</p>
        <p>4. Group assignments</p>
        <p>5. Live Classes</p>
        <br />
        <p>
          At the end of this bootcamp you would be required to build a final
          project which is a personal portfolio website.
        </p>
      </Card>
      <Card cardClass={styles.card}>
        <h4 className="--color-danger">Bootcamp Goals</h4>
        <p>
          The ultimate goal of this bootcamp is to help you transition from your
          current career or job to Tech (FrontEnd Web developer)
        </p>

        <br />
        <p>
          An average Junior Frontend developer working remotely earn about $30k
          - $50k depending on location and organization.
        </p>
        <br />
        <p>Are you ready to begin? Lets go...</p>
      </Card>
    </div>
  );
};

export default Home;
