import React from "react";

const Cohort = () => {
  return (
    <div className="--card --card-blue --width-500px --p --mx-auto">
      <div>
        <h4>
          Start Now <span className="--color-danger">or</span> Later ~ You
          Decide...
        </h4>
        <p className="--text-sm">
          Most people <b>ALREADY</b> have some knowledge about Web Development,
          i.e HTML, CSS, etc. We take this into account and we place you into a
          group that matches the knowledge you already have.
        </p>

        <p>
          <b className="--color-danger">This means</b> you can{" "}
          <b className="--color-danger">Start Your Training</b> right away.
        </p>
      </div>
    </div>
  );
};

export default Cohort;
