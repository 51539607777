import React from "react";
import Card from "../../card/Card";
import styles from "../Fed.module.scss";
import bsImg from "../../../assets/bootstrap.jpg";
import CourseCard from "../../courseCard/CourseCard";
import ContactLink from "../../contactLink/ContactLink";
import ProtectFed from "../../hiddenPages/protectFed";

const CssFramework = () => {
  return (
    <ProtectFed>
      <div>
        <h2>Bootstrap</h2>
        <hr />
        <br />
        <Card cardClass={styles.card}>
          <p>
            In this section, your are going to learn Bootstrap, a css framework
            that helps us write css faster.
          </p>
          {/* <br /> */}
          <p>As part of this bootcamp, begin the course below.</p>
          <br />
          <p>
            {" "}
            <b>Course Duration:</b> 1-week
          </p>
          <br />
          <p>
            <b>NOTE:</b> if you are fully enrolled in this bootcamp, you would
            have gotten an email giving you access to the course.
          </p>
          <br />
          <ContactLink />
        </Card>
        <br />
        <CourseCard
          img={bsImg}
          title={"Bootstrap"}
          link={"https://youtu.be/FvjPYx7K3UQ"}
          cta={"Begin Course"}
        />
      </div>
    </ProtectFed>
  );
};

export default CssFramework;
