import React from "react";
import "./About.scss";

const About = () => {
  return (
    <section>
      <div className="container">
        <div className="title">
          <h2 className="s-head --mb2">About us</h2>
          <p className="--my2">
            We know this is very important to you, <br />
            so here is a summary of what the FrontEnd Developer bootcamp covers.
          </p>
          <br />
        </div>
        {/* Video */}
        <div className="video --text-center">
          <iframe
            style={{ maxWidth: "100%" }}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/oDFbCojFDOU"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default About;
