import React from "react";
import { Route, Routes } from "react-router-dom";
import Conclusion from "../../components/fed/conclusion/Conclusion";
import CssFramework from "../../components/fed/cssframework/CssFramework";
import Home from "../../components/fed/home/Home";
import HtmlCss from "../../components/fed/htmlcss/HtmlCss";
import HtmlCssQuiz from "../../components/fed/htmlcssquiz/HtmlCssQuiz";
import HtmlCssTest from "../../components/fed/htmlcsstest/HtmlCssTest";
import Javascipt from "../../components/fed/javascript/Javascript";
import Javascipt2 from "../../components/fed/javascript2/Javascript2";
import Navbar from "../../components/fed/navbar/Navbar";
import ReactJS from "../../components/fed/react/React";
import Roadmap from "../../components/fed/roadmap/Roadmap";

import styles from "./Fed.module.scss";

const Fed = () => {
  return (
    <div className={styles.admin}>
      <div className={styles.navbar}>
        <Navbar />
      </div>
      <div className={styles.content}>
        <Routes>
          <Route path="home" element={<Home />} />
          <Route path="roadmap" element={<Roadmap />} />
          <Route path="html-css" element={<HtmlCss />} />
          <Route path="html-css-quiz" element={<HtmlCssQuiz />} />
          <Route path="html-css-test" element={<HtmlCssTest />} />
          <Route path="css-framework" element={<CssFramework />} />
          <Route path="javascript" element={<Javascipt />} />
          <Route path="javascript2" element={<Javascipt2 />} />
          <Route path="react" element={<ReactJS />} />
          <Route path="conclusion" element={<Conclusion />} />
        </Routes>
      </div>
    </div>
  );
};

export default Fed;
