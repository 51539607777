import { useContext } from "react";
import AllContext from "../../context/allContext";

export const ShowOnLogout = ({ children }) => {
  const { isLoggedIn } = useContext(AllContext);

  if (!isLoggedIn) {
    return children;
  }
  return null;
};

export default ShowOnLogout;
