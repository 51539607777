import { useContext } from "react";
import { NavLink } from "react-router-dom";
import styles from "./Navbar.module.scss";
import { FaUserCircle } from "react-icons/fa";
import AllContext from "../../../context/allContext";

const activeLink = ({ isActive }) => (isActive ? `${styles.active}` : "");

const Navbar = () => {
  const { username } = useContext(AllContext);
  return (
    <div className={styles.navbar}>
      <div className={styles.user}>
        <FaUserCircle size={40} color="#fff" />
        <h4>{username}</h4>
      </div>
      <nav>
        <ul>
          <li>
            <NavLink to="/admin/home" className={activeLink}>
              Home
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
