import React from "react";

const ContactLink = () => {
  return (
    <div>
      <span className="--text-p">Have any issue,</span>{" "}
      <a
        href="mailto: zinotrust@gmail.com"
        target="_blank"
        rel="noreferrer"
        className="--color-primary"
      >
        | Email me |
      </a>{" "}
      <span className="--text-p">OR </span>
      <a
        href="https://twitter.com/zinotrust"
        target="_blank"
        rel="noreferrer"
        className="--color-primary"
      >
        | DM me on Twitter |
      </a>
    </div>
  );
};

export default ContactLink;
