import { useState } from "react";
import styles from "./auth.module.scss";
import loginImg from "../../assets/login.svg";
import { useNavigate } from "react-router-dom";
import { FaGoogle } from "react-icons/fa";
import Card from "../../components/card/Card";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, db } from "../../firebase/config";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
import { doc, setDoc, Timestamp, getDoc } from "firebase/firestore";

const MyLogin = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // Login with Google
    const signInWithGoogle = async () => {
        setIsLoading(true);
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            if (user && user.uid) {
                // Check if user exists in Firestore
                const userDoc = await getDoc(doc(db, "users", user.uid));
                if (userDoc.exists()) {
                    // User already exists, sign in
                    setIsLoading(false);
                    toast.success("Login successfully");
                    navigate("/fed/home");
                } else {
                    // User does not exist, create new user entry
                    await setDoc(doc(db, "users", user.uid), {
                        id: user.uid,
                        name: user.displayName,
                        email: user.email,
                        access: "none",
                        cohort: "none",
                        createdAt: Timestamp.now().toDate(),
                    });
                    setIsLoading(false);
                    toast.success("Account created successfully");
                    navigate("/fed/home");
                }
            } else {
                setIsLoading(false);
                toast.error("User data not available.");
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(error.message);
        }
    };

    return (
        <>
            {isLoading && <Loader />}
            <section className={`container ${styles.auth}`}>
                <div className={styles.img}>
                    <img src={loginImg} alt="Login" width="300" />
                </div>

                <Card>
                    <div className={styles.form}>
                        <h2>Login</h2>
                        <button
                            onClick={signInWithGoogle}
                            className="--btn --btn-danger --btn-block"
                        >
                            <FaGoogle color="#fff" /> Login With Google
                        </button>
                    </div>
                </Card>
            </section>
        </>
    );
};

export default MyLogin;
