import { useContext, useEffect, useState } from "react";
import AllContext from "../../context/allContext";
import useFetchCollection from "../../customHooks/useFetchCollection";

const ShowOnlyAdmin = ({ children }) => {
  const [userAccess, setUserAccess] = useState("");
  const { data } = useFetchCollection("users");
  //   console.log(data);
  const { userID } = useContext(AllContext);
  // console.log(userID);

  useEffect(() => {
    setUserAccess("none");
    if (data !== []) {
      const userIndex = data.findIndex((item) => {
        return item.id === userID;
      });
      //   console.log(userIndex);
      if (userIndex !== -1) {
        const thisUser = data.find((item) => {
          return item.id === userID;
        });
        // console.log(thisUser);
        setUserAccess(thisUser.access);
      }
    }
  }, [data, userID]);

  if (userAccess === "admin") {
    return children;
  }
  return null;
};

export default ShowOnlyAdmin;
