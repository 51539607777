import React from "react";

const HtmlCssQuiz = () => {
  return (
    <div>
      <h2>HTML {"&"} CSS Quiz</h2>
      <hr />
      <p>
        Please take the quiz below to test your understanding of the previous
        section.
      </p>
      <br />
    </div>
  );
};

export default HtmlCssQuiz;
