import React from "react";
import Card from "../../card/Card";
import styles from "../Fed.module.scss";

const Roadmap = () => {
  return (
    <div>
      <h2>FrontEnd Roadmap</h2>
      <hr />
      <p>
        This is a list of programming languages/tools you would learn during
        this bootcamp.
      </p>
      <br />
      <Card cardClass={styles.card}>
        <h4 className="--color-danger">HTML</h4>
        <p>
          HTML stands for <b>Hypertext Markup language</b> and you would learn
          the following under it.
        </p>
        <ol className="--text-p --fw-bold --ml2">
          <li>Web page creation</li>
          <li>Basics - Tags, Element etc.</li>
          <li>Forms and Tables</li>
          <li>Links</li>
          <li>Lists</li>
          <li>Icons and entities</li>
          <li>Semantic Layout, etc.</li>
        </ol>
      </Card>
      <br />
      <Card cardClass={styles.card}>
        <h4 className="--color-danger">CSS</h4>
        <p>
          CSS stands for <b>Cascading stylesheet</b> and you would learn the
          following under it.
        </p>
        <ol className="--text-p --fw-bold --ml2">
          <li>Adding a stylesheet</li>
          <li>Selectors</li>
          <li>Positioning</li>
          <li>Box Model</li>
          <li>Display</li>
          <li>Specificity</li>
          <li>FlexBox</li>
          <li>Grid Layout</li>
          <li>Media Queries</li>
          <li>Pseudo Elements</li>
          <li>Pseudo Classes</li>
          <li>Animations</li>
          <li>SASS</li>
        </ol>
      </Card>
      <br />
      <Card cardClass={styles.card}>
        <h4 className="--color-danger">CSS Framework</h4>
        <p>
          CSS <b>frameworks</b> are technologies that helps us write css faster.
          There are many out there but the popular ones include
        </p>
        <ol className="--text-p --fw-bold --ml2">
          <li>Bootstrap</li>
          <li>Tailwind</li>
        </ol>
      </Card>
      <br />
      <Card cardClass={styles.card}>
        <h4 className="--color-danger">JavaScript</h4>
        <p>
          <b>JavaScipt</b> helps you add interactivity to you web pages and you
          would learn the following under it.
        </p>
        <ol className="--text-p --fw-bold --ml2">
          <li>Basic Syntax</li>
          <li>Variables</li>
          <li>Data structure</li>
          <li>Event Listeners</li>
          <li>DOM Manipulation</li>
          <li>HTTP Request / Fetch API / Ajax</li>
          <li>Async Await</li>
          <li>Object Oriented Programming</li>
          <li>Array Methods</li>
          <li>ES6+ JavaScript</li>
        </ol>
      </Card>
      <br />
      <Card cardClass={styles.card}>
        <h4 className="--color-danger">Git and GitHub</h4>
        <p>
          <b>Git and GitHub</b> are technologies that helps us manage our code
          and work with other developers and you would learn the following under
          it.
        </p>
        <ol className="--text-p --fw-bold --ml2">
          <li>Local Vs Remote Repository</li>
          <li>Git basics</li>
          <li>Staging and Commits</li>
          <li>Branching</li>
          <li>GitHub basics</li>
          <li>Git in VScode</li>
        </ol>
      </Card>
      <br />
      <Card cardClass={styles.card}>
        <h4 className="--color-danger">React</h4>
        <p>
          <b>Reach</b> is a JavaScript Library for building user interfaces and
          you would learn the following under it.
        </p>
        <ol className="--text-p --fw-bold --ml2">
          <li>JSX</li>
          <li>Components</li>
          <li>Props and state</li>
          <li>Hooks</li>
          <li>Custom Hooks</li>
          <li>State management</li>
          <li>Context API</li>
          <li>Redux</li>
          <li>Routing</li>
          <li>Data Fetching - Rest API/GraphQL</li>
          <li>Testing</li>
          <li>Deployment</li>
          <li>Backend service - Firebase</li>
        </ol>
      </Card>
      <br />
      <Card cardClass={styles.card}>
        <h4 className="--color-danger">What Next?</h4>
        <p>
          <b>Congratulations...</b> You have put in so much work to get here,
          now let's talk about what you should do next.
        </p>
        <ol className="--text-p --fw-bold --ml2">
          <li>Making money in Tech</li>
          <li>Getting a job</li>
          <li>Freelancing</li>
          <li>More learning</li>
        </ol>
      </Card>
    </div>
  );
};

export default Roadmap;
