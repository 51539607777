import React from "react";
import Card from "../../card/Card";
import ProtectFed from "../../hiddenPages/protectFed";
import ProjectCard from "../../projectCard/ProjectCard";
import styles from "../Fed.module.scss";

const HtmlCssTest = () => {
  return (
    <div>
      <h2>HTML {"&"} CSS Project Assignments</h2>
      <hr />
      <Card cardClass={styles.card}>
        <p>
          Please build out the projects below using the knowledge of html and
          css you have learnt in the previous section.
        </p>{" "}
        <br />
        <p>
          I encourage you to attempt the project either alone or with your team
          memvers before you watch the solution. Cheers...
        </p>
      </Card>
      <ProjectCard
        title={"Project 1 - URL Shortener"}
        desc={
          "This is a simple landing page designed for a url shortener website. Design credit: frontendmentor"
        }
        previewURL={"https://url-sh.netlify.app/"}
        downloadURL={
          "https://drive.google.com/uc?export=download&id=1W306Qa1CiGB7srUz1nV2SLbLwGypzqOa"
        }
        solutionURL={"https://www.youtube.com/watch?v=a6302W5b0Uw"}
        img={"https://i.ibb.co/K2727SF/url-sh.png"}
      />
      <ProjectCard
        title={"Project 2 - Landing Page"}
        desc={"This is a simple landing page website"}
        previewURL={"https://landing-pagez.netlify.app/"}
        downloadURL={
          "https://drive.google.com/uc?export=download&id=15WVNRzI7g5bmwiT36zRtk3-1XYUumntL"
        }
        solutionURL={"https://www.youtube.com/watch?v=LcGIqq2Z49g"}
        img={"https://i.ibb.co/7yTQsxh/landing-page.png"}
      />
      <ProjectCard
        title={"Project 3 - Agency Website"}
        desc={"This is a simple agency website."}
        previewURL={"https://digital-a.netlify.app/"}
        downloadURL={
          "https://drive.google.com/uc?export=download&id=1CbWIKhsrXtxYapGqDdidvl88xphi7iqm"
        }
        solutionURL={"https://www.youtube.com/watch?v=CeNB1E8UAAg"}
        img={"https://i.ibb.co/YyKPgNJ/design-agency.png"}
      />
    </div>
  );
};

export default HtmlCssTest;
