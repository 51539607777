import React from "react";

const PaymentPlan = ({
  theme,
  plan,
  desc,
  desc2,
  amount,
  naira,
  discount,
  paymentLink,
}) => {
  return (
    <div className="plans --card --card-grey --width-300px">
      <div className={`--p ${theme}`}>
        <h3 className="--text-light">{plan}</h3>
        <p className="--text-light">{desc}</p>
      </div>
      <hr />
      <div className="--p">
        <span>
          <span className="--text-lg">$</span>
          <span className="--text-lg">{amount}</span>
          <span className="--text-sm">{naira}</span>
          <p>
            <b>{desc2}</b>
          </p>
        </span>
        <br />
        <p className="--text-sm">
          With this payment option, you get the benefit of{" "}
          <b>{discount} discount</b> off the tuition fee.
        </p>
        <br />
        <a href={paymentLink} target="_blank" rel="noreferrer">
          <button className={`--btn ${theme}`}>Pay Now</button>
        </a>
      </div>
    </div>
  );
};

export default PaymentPlan;
