import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: "bootcampza-842d1.firebaseapp.com",
  projectId: "bootcampza-842d1",
  storageBucket: "bootcampza-842d1.appspot.com",
  messagingSenderId: "1037692949038",
  appId: "1:1037692949038:web:a92b403dbd16f287e885bf",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;
