import React from "react";
import "./Hero.scss";
import video from "../../../assets/Design-large.mp4";

const Hero = () => {
  return (
    <section className="intro-section">
      <div className="container intro-content">
        <div className="intro-text">
          <h1 className="--text-light">
            <span className="first-span">FrontEnd Developer Bootcamp...</span>
            <span className="--text-md --block">Learn</span>{" "}
            <span className="second-span"></span>
          </h1>
          <p className="--text-light --mb2 ">
            Welcome To Tech Bootcamp... <br />
            This bootcamp is designed to teach you Web Development.
            <br />
            To help you transition from your current career to Tech.
            <br />
            Even a 13year old can also enroll.
          </p>
          <p>
            <a href="#faq" className="btn">
              Learn More
            </a>
          </p>
        </div>
      </div>

      <div className="video-container">
        <div className="video-overlay"></div>
        <video autoPlay muted loop>
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </section>
  );
};

export default Hero;
