import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetchCollection from "../../../customHooks/useFetchCollection";

import Loader from "../../loader/Loader";
import styles from "./Users.module.scss";

const Users = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [usersPerPage] = useState(10);

  const { data, isLoading } = useFetchCollection("users");

  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/admin/user-details/${id}`);
  };

  const filteredUsers = data.filter(
    (user) =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);


  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const handleSearchInputChange = (e) => {
    setCurrentPage(1);
    setSearchQuery(e.target.value);
  };



  return (
    <>
      <div className={styles.order}>
        <h2>List of Users</h2>
        <p>
          Click on a user to <b>Change user role</b>
        </p>
        <br />
        <>
          <div className={styles.search}>
            <input
              type="text"
              placeholder="Search by Username or Email"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />

            <p><b>Number of Users: {data.length}</b></p>
          </div>
          {isLoading && <Loader />}
          <div className={styles.table}>
            {currentUsers.length === 0 ? (
              <p>No User found</p>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>s/n</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Cohort</th>
                    <th>Access</th>
                  </tr>
                </thead>
                <tbody>
                  {currentUsers.map((user, index) => {
                    const { id, access, cohort, email, name } = user;
                    return (
                      <tr key={id} onClick={() => handleClick(id)}>
                        <td>{index + 1}</td>
                        <td>{name}</td>
                        <td>{email}</td>
                        <td>{cohort}</td>
                        <td>{access}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}


            <ul className={styles.pagination}>
              {filteredUsers.length > usersPerPage &&
                Array(Math.ceil(filteredUsers.length / usersPerPage))
                  .fill()
                  .map((_, i) => (
                    <li
                      key={i}
                      className={`${styles.pageItem} ${currentPage === i + 1 && styles.active
                        }`}
                      onClick={() => paginate(i + 1)}
                    >
                      <span>{i + 1}</span>
                    </li>
                  ))}
            </ul>
          </div>
        </>
      </div>
    </>
  );
};

export default Users;
