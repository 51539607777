import React from "react";
import Cohort from "../cohort/Cohort";
import ContactUs from "../contactUs/ContactUs";

const Success = () => {
  return (
    <section>
      <div className="container">
        <div className="title">
          <h2 className="s-head --mb2">Payment Successful</h2>
          <Cohort />
          <div className="--center-all">
            <p className="--my2 --width-500px">
              Hello, your payment was successul.
              <br />
              We would get back to you ASAP (within 24hrs).
            </p>
          </div>
          <ContactUs />
        </div>
      </div>
    </section>
  );
};

export default Success;
