import React, { useEffect, useState } from "react";
import useFetchDocument from "../../../customHooks/useFetchDocument";
import styles from "./UserDetails.module.scss";
import spinnerImg from "../../../assets/spinner.jpg";
import { Link, useParams } from "react-router-dom";
import Card from "../../card/Card";
import cardStyles from "../Admin.module.scss";
import ChangeUserStatus from "../changeUserStatus/ChangeUserStatus";

const UserDetails = () => {
  const [user, setUser] = useState(null);
  const { id } = useParams();
  const { document } = useFetchDocument("users", id);

  useEffect(() => {
    setUser(document);
  }, [document]);

  return (
    <>
      <div className={styles.table}>
        <h2>User Details</h2>
        <div>
          <Link to="/admin/home">&larr; Back To Orders</Link>
        </div>
        <br />
        {user === null ? (
          <img src={spinnerImg} alt="Loading..." style={{ width: "50px" }} />
        ) : (
          <>
            <Card cardClass={cardStyles.card}>
              <p>
                <b>Username</b> {user.name}
              </p>
              <hr />
              <p>
                <b>Email</b> {user.email}
              </p>
              <hr />
              <p>
                <b>Access</b> {user.access}
              </p>
              <hr />
              <p>
                <b>Cohort</b> {user.cohort}
              </p>
              <hr />
              <p>
                <b>User ID</b> {user.id}
              </p>
            </Card>

            <br />
          </>
        )}
        <ChangeUserStatus user={user} id={id} />
      </div>
    </>
  );
};

export default UserDetails;
